import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Historie a vývoj biogeografie" />
    <h1>Historie a vývoj biogeografie</h1>

    <p>Historie biogeografie sah&aacute; již do pravěku, kdy lovci měli z&aacute;kladn&iacute; znalost migračn&iacute;ch tras lovn&eacute; zvěře. Za poč&aacute;tek biogeografie jako vědy je v&scaron;ak považov&aacute;n poč&aacute;tek 18. stolet&iacute;. Za kl&iacute;čov&aacute; obdob&iacute; historie biogeografie považujeme zejm&eacute;na:</p>
    <ul>
    <li><strong><Link to="/biogeografie/historie/#18">dobu objevitelsk&yacute;ch cest v 18. stolet&iacute;</Link></strong></li>
    <li><strong><Link to="/biogeografie/historie/#19">biogeografii 19. stolet&iacute;</Link></strong></li>
    <li><strong><Link to="/biogeografie/historie/#1-20">biogeografii prvn&iacute; poloviny 20. stolet&iacute;</Link></strong></li>
    <li><strong><Link to="/biogeografie/historie/#2-20">biogeografii druh&eacute; poloviny 20. stolet&iacute;</Link></strong></li>
    </ul>
    <hr />
    <h2>Obdob&iacute; historie biogeografie</h2>
    <h3 id="18">Doba objevitelsk&yacute;ch cest v 18.&nbsp;stolet&iacute;</h3>
    <p>Doba objevitelsk&yacute;ch cest v 18. stolet&iacute; je i obdob&iacute;m popisov&aacute;n&iacute;m organismů (nejv&yacute;razněj&scaron;&iacute;mi osobnostmi jsou v tomto ohledu C.&nbsp;Linn&eacute; a&nbsp;Comte&nbsp;de&nbsp;Buffon). Z&aacute;roveň prob&iacute;haly pro (nejen) biogeografii stěžejn&iacute; cesty Jamese Cooka (lodě HMS Resolution a HMS Voyager). Ač vl&aacute;dne přesvědčen&iacute;, že živ&eacute; organismy byly stvořeny bohem, bylo v tomto obdob&iacute; učiněno několik z&aacute;sadn&iacute;ch biogeografick&yacute;ch poznatků:</p>
    <ul>
    <li>pozorov&aacute;n&iacute; rozd&iacute;ln&eacute; bioty v různ&yacute;ch č&aacute;stech světa vede k prvn&iacute;m <strong>fytogeografick&yacute;m regionalizac&iacute;m</strong></li>
    <li>pozorov&aacute;n&iacute; <strong>vegetačn&iacute; stupňovitosti</strong></li>
    <li>objeven&iacute; <strong>latitud&aacute;ln&iacute;ho gradientu diverzity</strong></li>
    </ul>
    <h4>Carl Linn&eacute; a Hora r&aacute;je</h4>
    <p>&Scaron;v&eacute;d <strong>Carl Linn&eacute;</strong> byl zakladatelem botanick&eacute; a zoologick&eacute; nomenklatury a zavedl pojem <Link to="/biogeografie/definice/#druh">druh</Link>. Pokud se vyskytuje v nomenklatuře za jm&eacute;nem druhu "<em>L.</em>", tak byl pops&aacute;n pr&aacute;vě Linn&eacute;m.</p>
    <p>Kromě popisov&aacute;n&iacute; druhů se snažil vysvětlit tak&eacute; jejich původ a &scaron;&iacute;řen&iacute;. Domn&iacute;val se, že živ&eacute; organizmy jsou neměnn&eacute; a jsou již od prvopoč&aacute;tku adaptovan&eacute; na sv&eacute; prostřed&iacute;. Podle Linn&eacute;ho do&scaron;lo k jejich <strong>stvořen&iacute; na svaz&iacute;ch tropick&eacute; ostrovn&iacute; hory</strong> (tzv. Hora r&aacute;je), kter&aacute; měla v&scaron;echna stanovi&scaron;tě - od tropick&eacute;ho de&scaron;tn&eacute;ho lesa až po tundru. Po &uacute;stupu moře se pak pr&yacute; z t&eacute;to hory roz&scaron;&iacute;řily po cel&eacute;m světě. Analogicky vysvětloval &scaron;&iacute;řen&iacute; druhů po biblick&eacute; potopě (z hory Ararat).</p>
    <h4>Comte de Buffon a teorie severn&iacute;ho původu</h4>
    <p>Francouzsk&yacute; př&iacute;rodovědec <strong>Comte de Buffon</strong> (či tak&eacute; <strong>Georges-Louis Leclerc</strong>) byl současn&iacute;k Linn&eacute;ho a oponent jeho my&scaron;lenek. V teorii stvořen&iacute; organismů na svaz&iacute;ch ostrovn&iacute; hory a jejich neměnnosti vid&iacute; dva z&aacute;sadn&iacute; probl&eacute;my:</p>
    <ul>
    <li>různ&eacute; oblasti světa s prakticky stejn&yacute;mi př&iacute;rodn&iacute;mi podm&iacute;nkami jsou domovem odli&scaron;n&yacute;ch druhů rostlin a živočichů</li>
    <li>předem adaptovan&eacute; a neměnn&eacute; druhy by se musely z ostrovn&iacute; hory (či Araratu) &scaron;&iacute;řit přes z&oacute;ny s nepř&iacute;zniv&yacute;m prostřed&iacute;m (největ&scaron;&iacute; přek&aacute;žkou jsou zejm&eacute;na pou&scaron;tě)</li>
    </ul>
    <p>Nam&iacute;sto toho přich&aacute;z&iacute; s <strong>teori&iacute; severn&iacute;ho původu</strong> organismů. Podle Leclerca se rostlinn&eacute; i živoči&scaron;n&eacute; druhy začaly po ochlazen&iacute; &scaron;&iacute;řit směrem na jih a postupně se měnily. Na z&aacute;kladě t&eacute;to teorie i vyj&aacute;dřil tzv. <strong>Buffon's&nbsp;law</strong>. Buffonův z&aacute;kon hovoř&iacute; o tom, že izolovan&eacute; a př&iacute;rodn&iacute;mi podm&iacute;nkami podobn&eacute; regiony host&iacute; odli&scaron;n&eacute; druhy savců a pt&aacute;ků (origin&aacute;lně jen v tropech). Z&aacute;kon byl pak <Link to="/biogeografie/historie/#forster">Forsterem</Link> roz&scaron;&iacute;řen prostorově (i mimo tropy) a <Link to="/biogeografie/historie/#humboldt">Humboldtem</Link> na dal&scaron;&iacute; druhy organismů.</p>
    <h4 id="forster">Johan Reinhold Forster, ostrovn&iacute; biogeografie a latitud&aacute;ln&iacute; trend diverzity</h4>
    <p>Německ&yacute; pastor a př&iacute;rodovědec Johan Reinhold Forster se z&uacute;častnil 2.&nbsp;expedice Jamese Cooka. Roz&scaron;&iacute;řil Buffonův z&aacute;kon prostorově i do dal&scaron;&iacute;ch oblast&iacute; než jen tropů a popsal (bio)regiony světa na z&aacute;kladě roz&scaron;&iacute;řen&iacute; rostlin.</p>
    <p>D&aacute;le učinil pozorov&aacute;n&iacute;, že izolovan&aacute; ostrovn&iacute; společenstva m&iacute;vaj&iacute; m&eacute;ně druhů než společenstva na pevnině, č&iacute;mž položil z&aacute;klady <strong>ostrovn&iacute; biogeografie</strong>. Rovněž objevil <strong>latitud&aacute;ln&iacute; trend diverzity</strong>, tedy že počty druhů (jejich diverzita) klesaj&iacute; se stoupaj&iacute;c&iacute; zeměpisnou &scaron;&iacute;řkou (od rovn&iacute;ku k p&oacute;lům).</p>
    <h4 id="humboldt">Alexander Humboldt a&nbsp;vegetačn&iacute; stupně</h4>
    <p>Německ&yacute; př&iacute;rodovědec a geograf Alexander Humboldt je prakticky otcem fytogeografie. Rovněž se považuje za zakladatele vulkanologie, antropologie, meteorologie, oce&aacute;nografie či archeologie. Roz&scaron;&iacute;řil Buffonovo pravidlo na vět&scaron;inu terestrick&yacute;ch organismů (rostliny a živočichov&eacute;).</p>
    <p>Humboldt pozoroval, že druhy rostlin se neměn&iacute; jen mezi regiony, ale tak&eacute; v r&aacute;mci jednoho regionu pod&eacute;l v&yacute;&scaron;kov&eacute;ho gradientu. Na <a href="https://cs.wikipedia.org/wiki/Chimborazo">Chimborazo v Ekv&aacute;doru</a> tak poprv&eacute; popsal <strong>vegetačn&iacute; stupně</strong>.</p>
    <hr />
    <h3 id="19">Biogeografie v 19.&nbsp;stolet&iacute;</h3>
    <p>Biogeografie se v 19.&nbsp;stolet&iacute; stala etablovanou vědou a biogeografov&eacute; mohou již pracovat s v&iacute;ce poznatky. Mezi kl&iacute;čov&eacute; poznatky pro biogeografii patř&iacute; v tomto obdob&iacute;:</p>
    <ul>
    <li><strong>lep&scaron;&iacute;mi odhady st&aacute;ř&iacute; planety Země</strong> (doposud předpoklad, že Země nen&iacute; star&scaron;&iacute; než 10&nbsp;000&nbsp;let)</li>
    <li><strong>teorie kontinent&aacute;ln&iacute;ho driftu a deskov&eacute; tektoniky</strong></li>
    <li><strong>lep&scaron;&iacute; pochopen&iacute; mechanismu &scaron;&iacute;řen&iacute; a diverzifikace druhů</strong></li>
    <li><strong>rozvoj ekologie a zoogegorafie</strong></li>
    <li><strong>stanoven&iacute; několika biogeografick&yacute;ch pravidel</strong></li>
    </ul>
    <h4>Charles Lyell a princip uniformity</h4>
    <p>Skotsk&yacute; geolog Charles Lyell studoval fosiln&iacute; organismy a poskytl <strong>důkazy pro vym&iacute;r&aacute;n&iacute; druhů</strong>. Věřil v&scaron;ak, že druhy jsou neměnn&eacute; a extinkce je vždy n&aacute;sledov&aacute;na stvořen&iacute;m stejn&yacute;ch druhů. Druhouv&aacute; bohatost by podle Lyella tak byla v&iacute;cem&eacute;ně neměnn&aacute;.</p>
    <p>Lyell zjistil, že změny ve fosiln&iacute;m z&aacute;znamu jsou d&aacute;ny fyzick&yacute;mi procesy (např. vr&aacute;sněn&iacute; a změny klimatu). Ty prob&iacute;haly podle <strong>principu uniformity </strong>(tj. <strong>uniformitarianismus</strong>), tedy tak, že se z&aacute;sadně neli&scaron;ily od procesů současn&yacute;ch. Na z&aacute;kladě toho <strong>zpřesnil odhad st&aacute;ř&iacute; planety Země</strong> a určil, že jej&iacute; st&aacute;ř&iacute; mus&iacute; b&yacute;t vět&scaron;&iacute; než 10&nbsp;000&nbsp;let.</p>
    <h4>Charles Robert Darwin a evolučn&iacute; teorie</h4>
    <p>Britsk&yacute; př&iacute;rodovědec Darwin (cel&yacute;m jm&eacute;nem Charles Robert Darwin) je prakticky zakladatelem evolučn&iacute; biologie. V roce 1859 vydal stěžejn&iacute; d&iacute;lo <em>On the&nbsp;<strong>Origin of Species by Means of Natural Selection</strong>, or the Preservation of Favoured Races in the Struggle for Life</em> (česky <em>O&nbsp;původu druhů</em>).</p>
    <p>Z&aacute;kladn&iacute; <strong>premisa Darwinovy evolučn&iacute; teorie zn&iacute;</strong>, že <strong>adaptace a diverzifikace organismů je v&yacute;sledkem př&iacute;rodn&iacute;ho v&yacute;běru</strong>. Z&aacute;kladn&iacute;mi zdroji jeho teorie je Lyellovo d&iacute;lo <em>Principles of Geology</em> a plavba na HMS&nbsp;Beagle (mastodont v Argentině, želvy a pěnkavy na Galap&aacute;g&aacute;ch).</p>
    <p>Darwinova teorie stoj&iacute; na předpokladech vzniku v&scaron;ech druhů ze společn&eacute;ho předka (nikoliv nadpřirozenou bytost&iacute; či samoplozen&iacute;m) a divergence způsoben&eacute; akumulac&iacute; drobn&yacute;ch změn a na teorii př&iacute;rodn&iacute;ho v&yacute;běru.</p>
    <p>Samotn&aacute; evolučn&iacute; teorie nebyla přij&iacute;m&aacute;na, mimo jin&eacute; i kvůli tehdej&scaron;&iacute; neznalosti dědičnosti. Probl&eacute;mem bylo tak&eacute; uvažovan&eacute; st&aacute;ř&iacute; Země, kter&eacute; bylo odhadov&aacute;no na maxim&aacute;lně 200&nbsp;tis&iacute;c let.</p>
    <h4>Joseph Dalton Hooker a&nbsp;teorie pevninsk&yacute;ch mostů</h4>
    <p>Britsk&yacute; botanik Joseph Dalton Hooker byl stoupenec darwinismu. Dalton zast&aacute;val přesvědčen&iacute;, že rostliny se mohou &scaron;&iacute;řit na velk&eacute; vzd&aacute;lenosti. V&scaron;&iacute;mal si totiž podobnost&iacute; fl&oacute;ry v různ&yacute;ch regionech zejm&eacute;na na jižn&iacute; polokouli. S ohledem na v&scaron;eobecně přij&iacute;man&yacute; n&aacute;zor, že poloha kontinentů je neměnn&aacute;, domn&iacute;val se, že kontinenty byly dř&iacute;ve spojeny <strong>pevninsk&yacute;mi mosty</strong>, přes něž mohla květena migrovat.</p>
    <h4>Ernst Haeckel a Haeckelův z&aacute;kon</h4>
    <p>Německ&yacute; př&iacute;rodovědec Erns Haeckel byl stoupenec darwinismu, popsal tis&iacute;ce druhů hmyzu (do t&eacute; doby pozornost biogeografie upřena zejm&eacute;na na rostliny), sestavil fylogenetick&eacute; dokumenty a začlenil člověka do celkov&eacute; evoluce. Heackel tak&eacute; zav&aacute;d&iacute; n&aacute;sleduj&iacute;c&iacute; pojmy:</p>
    <ul>
    <li><strong>ekologie</strong></li>
    <li><strong>fylogeneze</strong> (v&yacute;voj druhu)</li>
    <li><strong>ontogeneze</strong> (v&yacute;voj jedince)</li>
    </ul>
    <p>T&eacute;ž představil tzv. <strong>Haeckelův z&aacute;kon</strong>, kter&yacute; v origin&aacute;le zn&iacute;: "<em>Ontogeny recapitules phylogeny</em>". Tedy že v&yacute;voj jedince zrcadl&iacute; v&yacute;voj druhu. Př&iacute;kladem může b&yacute;t v&yacute;voj pulce v ž&aacute;bu nebo že m&aacute; embryo oc&aacute;sek. Tento z&aacute;kon v&scaron;ak neplat&iacute; př&iacute;li&scaron; často.</p>
    <h4>Philip Lutley Sclater a vymezen&iacute; zoogeografick&yacute;ch regionů</h4>
    <p>Britsk&yacute; ornitolog Philip Lutley Sclater popsal v&iacute;ce než tis&iacute;c druhů pt&aacute;ků a poprv&eacute; <strong>vymezil zoogeografick&eacute; regiony</strong><strong>na z&aacute;kladě roz&scaron;&iacute;řen&iacute; pt&aacute;ků</strong> (konkr&eacute;tně pěvců).</p>
    <p>Podobnost fos&iacute;li&iacute; prim&aacute;tů z Madagaskaru a Indie, kter&eacute; studoval, si vysvětloval existenc&iacute; zanikl&eacute;ho kontinetu, kter&yacute; je spojoval (tzv. Lemuria).</p>
    <h4>Alfred Russel Wallace, Wallacea a Wallaceova linie</h4>
    <p>Britsk&yacute; ornitolog Alfred Russel Wallace je zakladatelem zoogeografie a roz&scaron;&iacute;řil zoogeografick&eacute; členěn&iacute; světa podle Sclatera na v&scaron;echny obratlovce a vypracoval mapu biogeografick&yacute;ch regionů světa. Nez&aacute;visle na Darwinovy tvoř&iacute; <strong>vlastn&iacute; evolučn&iacute; teorii</strong>, kter&aacute; stoj&iacute; na principu &scaron;těpen&iacute; druhů.</p>
    <p>Wallace vytovřil <strong>množstv&iacute; biogeografick&yacute;ch pravidel</strong> a z&aacute;konitost&iacute;, kter&eacute; byly na svou dobu velmi pokročil&eacute;. Při studiu fauny v <strong>jihov&yacute;chodn&iacute; Asii</strong> popsal unik&aacute;tn&iacute; <strong>biogeografick&eacute; struktury</strong>:</p>
    <ul>
    <li><strong>Wallacea</strong> = Přechodn&aacute; oblast mezi indomalajskou a australskou zoogeografickou oblast&iacute; v JV Asii, vyznačuj&iacute;c&iacute; se vysok&yacute;m endemismem.</li>
    <li><strong>Wallaceova linie</strong> = Na obou stran&aacute;ch t&eacute;to linie v JV Asii jsou totožn&eacute; klimatick&eacute; podm&iacute;nky, ale odli&scaron;n&aacute; fauna.</li>
    </ul>
    <hr />
    <h3 id="1-20">Biogeografie prvn&iacute; poloviny 20.&nbsp;stolet&iacute;</h3>
    <p>Rozvoj paleontologie v prvn&iacute; polovině 20.&nbsp;stolet&iacute; přin&aacute;&scaron;&iacute; nov&eacute; poznatky o fosiln&iacute;ch faun&aacute;ch a fl&oacute;r&aacute;ch. Pro biogeografii je důležit&eacute;, že z fosiln&iacute;ch z&aacute;znamů zač&iacute;n&aacute; b&yacute;t možn&eacute; pozorovat vznik, &scaron;&iacute;řen&iacute;, diverzifikace a extinkce organismů.</p>
    <p>D&aacute;le doch&aacute;z&iacute; ke vzniku fylogenetick&yacute;ch stromů, konceptu biologick&eacute;ho druhu či se rozv&iacute;j&iacute; rozličn&eacute; &scaron;koly rostlinn&eacute; sociologie. Poprv&eacute; je formulov&aacute;n koncept kontinent&aacute;ln&iacute;ho driftu, teorie o pevninsk&yacute;ch mostech či zanikl&yacute;ch kontinentech v&scaron;ak zůst&aacute;vaj&iacute; popul&aacute;rněj&scaron;&iacute;.</p>
    <h4>Alfred Wegener a teorie kontinent&aacute;ln&iacute;ho driftu</h4>
    <p>Německ&yacute; meteorolog a geolog Alfred Wegener při&scaron;el v roce 1915 na z&aacute;kladě fosiln&iacute;ch n&aacute;lezů a tvarů kontinetů na <strong>teorii kontinent&aacute;ln&iacute;ho driftu</strong>. Teorie byla zprvu odm&iacute;tnuta, pohyb organismů bylo jednodu&scaron;&scaron;&iacute; vysvětlen&iacute; pro stejn&eacute; fos&iacute;lie v naprosto rozli&scaron;n&yacute;ch oblastech než pohyb samotn&yacute;ch kontinentů. Teorie kontinent&aacute;ln&iacute;ho driftu byla akceptov&aacute;na až koncem 60.&nbsp;let 20.&nbsp;stolet&iacute;, kdy byl objeven mechanismus pohybu litosferick&yacute;ch desek.</p>
    <h4>Ernst Walter Mayr a koncept biologick&eacute;ho druhu</h4>
    <p>Taxonom a ornitolog Ernst Walter Mayr byl jedn&iacute;m z největ&scaron;&iacute;ch evolučn&iacute;ch biologů 20.&nbsp;stolet&iacute;. Mimo jin&eacute;ho přich&aacute;z&iacute; s <strong>konceptem biologick&eacute;ho druhu </strong>tak, jak je <Link to="/biogeografie/definice/#druh">pops&aacute;n v minul&eacute; kapitole</Link>. D&aacute;le např&iacute;klad popisuje <strong>alopatrickou speciaci</strong>, tedy vznik druhů na z&aacute;kladě rozdělen&iacute; are&aacute;lu a n&aacute;sledn&eacute; geografick&eacute; izolaci jednotliv&yacute;ch populac&iacute;.</p>
    <hr />
    <h3 id="2-20">Biogeografie druh&eacute;&nbsp;poloviny 20.&nbsp;stolet&iacute;</h3>
    <p>V biogeografii druh&eacute;&nbsp;poloviny 20.&nbsp;stolet&iacute; zač&iacute;n&aacute; b&yacute;t kladen důraz na rozli&scaron;ov&aacute;n&iacute; stochastick&yacute;ch a deterministick&yacute;ch procesů (testov&aacute;n&iacute; hypot&eacute;z, použ&iacute;v&aacute;n&iacute; numerick&yacute;ch metod). Biogeografie je značně ovlivněna nov&yacute;mi technologiemi, předev&scaron;&iacute;m pak poč&iacute;tači, GIS, satelitn&iacute;mi sn&iacute;mky, molekul&aacute;rn&iacute;mi metodami či datab&aacute;zemi.</p>
    <p>Po akceptov&aacute;n&iacute; teorie kontinent&aacute;ln&iacute;ho driftu jsou definitivně opu&scaron;těny teorie pevninsk&yacute;ch mostů. V tomto obdob&iacute; doch&aacute;z&iacute; mimo jin&eacute; ke studiu koexistence druhů, je předložen koncept druhov&eacute; diverzity či je přednesena teorie ostrovn&iacute; biogegorafie.</p>
    <h4>Robert Harding Whittaker a&nbsp;studium diverzity</h4>
    <p>Americk&yacute; vegetačn&iacute; ekolog Robert Harding Whittaker studoval diverzitu vegetace pomoc&iacute; numerick&yacute;ch metod a ustanovil <strong>způsob v&yacute;počtu indexu diverzity a gradientov&eacute; anal&yacute;zy</strong>.</p>
    <p>D&aacute;le zavedl roku 1960 pojmy <strong>alfa, beta a gama diverzita</strong> a&nbsp;o&nbsp;9&nbsp;let později navrhuje <strong>klasifikaci bioty na 5&nbsp;ř&iacute;&scaron;&iacute;</strong>, a to <em>Animalia, Plantae, Fungi, Protista</em> a <em>Monera</em>. Rovněž navrhl vlastn&iacute; klasifikaci biomů na z&aacute;kladě jejich teploty a&nbsp;sr&aacute;žek.</p>
    <h4>MacArthur, Wilson a Teorie ostrovn&iacute; biogeografie</h4>
    <p>Američt&iacute; ekologov&eacute; Robert H.&nbsp;MacArthut a&nbsp;Edward O.&nbsp;Wilson společně v 60.&nbsp;letech vypracovali d&iacute;lo <strong>Teorie ostrovn&iacute; biogeografie</strong>. Teorie popisuje <strong>z&aacute;konitosti imigrace a extinkce na ostrovech vzhledem k jejich velikosti a izolovanosti</strong>. Autoři napsali Teorii ostrovn&iacute; biogeografie předev&scaron;&iacute;m na z&aacute;kladě vlastn&iacute;ho studia mravenců na ostrovech v Melan&eacute;sii. Toto d&iacute;lo se pak stalo jedn&iacute;m z pil&iacute;řů modern&iacute; ochrany př&iacute;rody.</p>
    <h4>James H. Brown a vznik makroekologie</h4>
    <p>Ekolog a profesor biologie University of New Mexico se věnuje populačn&iacute; ekologii a ekologii společenstev hlodavců a mravenců a&nbsp;biogeografii obecně.</p>
    <p>V roce 1989 společně s B.&nbsp;Mauerem zav&aacute;d&iacute; term&iacute;n <strong>makroekologie</strong>. Makroekologie je velmi rigor&oacute;zn&iacute; věda zab&yacute;vaj&iacute;c&iacute; se <strong>obecn&yacute;mi ekologick&yacute;mi ot&aacute;zkami na velk&yacute;ch prostorov&yacute;ch i časov&yacute;ch &scaron;k&aacute;l&aacute;ch</strong>, kter&aacute; využ&iacute;v&aacute; velk&yacute;ch souborů dat a složit&yacute;ch statistick&yacute;ch metod.</p>
    <p>James H.&nbsp;Brown je mimo jin&eacute; tak&eacute; jedn&iacute;m z autorů <strong>Metabolick&eacute; teorie</strong> ekologie.</p>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Historie biogeografie</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/definice/"><button className="inv">&larr; Definice biogeografie</button></Link>
    <Link to="/biogeografie/ekologicka/"><button className="inv">Ekologick&aacute; biogeografie &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
